.storybook-input-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.storybook-input-content-input {
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: flex-end;
}

.storybook-label {
  margin: 10px 0;
  text-align: left;
  color: white;
}

.storybook-input {
  border: 1.6px solid #9aa2b0;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  outline: none;
}

.storybook-input--primary {
  color: #8da0a5;
  background-color: white;
}

.storybook-input--secondary {
  color: black;
  background-color: transparent;
}

.storybook-input-radio {
  border: 1px solid white;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 15%);
}

.storybook-input-radio:hover,
.storybook-input-radio.isChecked {
  border: 1px solid #da2d64;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  background-color: transparent;
  color: white;
  background-color: rgba(255, 255, 255, 15%);
}

/* .storybook-input-radio-circle {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 10px;
  margin-left: 20px;
}

.storybook-input-radio:hover .storybook-input-radio-circle {
  background-color: #da2d64;
} */

.not-button {
  position: absolute;
  margin-right: 15px;
}

.not-button svg {
  fill: #8da0a5;
}

.Signin_form-inputs {
  margin-bottom: 25px;
}
