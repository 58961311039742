.Anticipation {
  display: flex;
  border-radius: 10px;
}

.Anticipation_left {
  background-color: #ff7d35;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Anticipation_left-image {
  margin: 40px;
  width: 80%;
}

.Anticipation_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Anticipation_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Anticipation_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Anticipation_right-title {
  color: #ff7d35;
  bottom: 20px;
  border-bottom: 1px solid #ff7d35;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Anticipation_right-question,
.Anticipation_right-fall,
.Anticipation_right-goals,
.Anticipation_right-calendar {
  display: flex;
  border-bottom: 1px solid #ff7d35;
  padding: 20px;
}

.Anticipation_right-question-content {
  margin-left: 20px;
}

.Anticipation_right-question-content-title,
.Anticipation_right-fall-content-title,
.Anticipation_right-goals-content-title,
.Anticipation_right-calendar-content-title,
.Anticipation_right-fight-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Anticipation_right-fall-content {
  margin-right: 20px;
}
.Anticipation_right-fall-content-lists {
  margin-top: 10px;
}

.Anticipation_right-fall-content-list {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  justify-content: flex-start;
}

.Anticipation_right-fall-content-list-text {
  margin-left: 10px;
  text-align: left;
}

.Anticipation_right-goals {
  justify-content: space-between;
}

.Anticipation_right-goals-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Anticipation_right-goals-lists {
  margin-left: 10px;
}

.Anticipation_right-goals-list {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
}

.Anticipation_right-goals-list-checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid #ff7d35;
  border-radius: 2px;
  margin-right: 5px;
}

.Anticipation_right-calendar-content {
  text-align: left;
  margin-left: 20px;
}

.Anticipation_right-fight {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
}

.Anticipation_right-fight-content {
  text-align: left;
  margin-bottom: 10px;
}

@media screen and (max-width: 550px) {
  .Anticipation {
    flex-direction: column;
  }
  .Anticipation_left,
  .Anticipation_right {
    border-radius: 0;
  }
}
