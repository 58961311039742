.TypeOfChallenges {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TypeOfChallenges_name {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TypeOfChallenges_challenges {
  display: flex;
  flex-wrap: wrap;
}
