.FirstConnexion_intro {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15%;
}

.FirstConnexion_firstname {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15%;
  width: 50% !important;
  margin-left: 25%;
}

.FirstConnexion_intro_image {
  margin: 30px;
}
