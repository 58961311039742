.Fear {
  display: flex;
  border-radius: 10px;
}

.Fear_left {
  background-color: #6fa83f;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Fear_left-image {
  margin: 40px;
  width: 80%;
}

.Fear_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Fear_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Fear_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Fear_right-title {
  color: #6fa83f;
  bottom: 20px;
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Fear_right-temperature {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #6fa83f;
  flex: 1 1;
}

.Fear_right-temperature-image {
  margin: 0 20px;
}

.Fear_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Fear_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Fear_right-zen,
.Fear_right-friend {
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Fear_right-zen-text,
.Fear_right-friend-text {
  margin-right: 20px;
  text-align: left;
}

.Fear_right-zen-text {
  margin: 5px 20px 20px 20px;
}

.Fear_right-song {
  padding: 20px;
  border-bottom: 1px solid #6fa83f;
}

.Fear_right-environment p {
  text-align: left;
}

.Fear_right-environment {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #6fa83f;
  flex: 1 1;
}

.Fear_right-freeze {
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
}

.Fear_right-freeze-title {
  font-weight: bold;
}

.Fear_right-reactions {
  display: flex;
  margin-top: 20px;
}

.Fear_right-reactions-left-reaction,
.Fear_right-reactions-right-reaction {
  display: flex;
}

.Fear_right-reactions-left,
.Fear_right-reactions-right,
.Fear_right-reactions-right-reaction {
  flex: 1 1;
  margin: 0 5px;
}

.Fear_right-reactions-left-reaction,
.Fear_right-reactions-right-reaction,
.Fear_right-reactions-left-reaction {
  display: flex;
  align-items: center;
}

.Fear_right-reactions-left-reaction-text {
  margin-left: 20px;
  text-align: left;
}

@media screen and (max-width: 550px) {
  .Fear {
    flex-direction: column;
  }
  .Fear_left,
  .Fear_right {
    border-radius: 0;
  }
}
