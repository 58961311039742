.QuestionSolution {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 40px 0 40px;
}

.QuestionSolution_information {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px 40px 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.QuestionSolution_information img {
  height: 35px;
  width: 35px;
  margin-bottom: 20px;
}

.QuestionSolution_information-link-text {
  margin: 20px 0;
}

.QuestionSolution li {
  margin-left: 40px;
}

.QuestionSolution_practice {
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px;
  border-radius: 10px;
  margin: 5% 0;
}

.QuestionSolution_practice-title {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.QuestionSolution_practice-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
