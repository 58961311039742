.SignupSuccess {
  display: flex;
  height: 100%;
}

.SignupSuccess_content {
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex: 1 1;
  background-color: white;
  color: #364257;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
}

.SignupSuccess_content svg {
  background-color: #f8d5e0;
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50px;
}

.SignupSuccess_content-title {
  margin: 25px 0;
}

.SignupSuccess_content-description {
  color: #9aa2b0;
  margin-bottom: 50px;
}
