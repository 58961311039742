.NavBar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #331443;
  padding: 0 10px 10px 10px;
  font-size: 14px;
}

.NavBar_logo {
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.NavBar_menu {
  display: flex;
}

.NavBar_menu-link.active {
  background: #331443;
  color: #ffc107;
}

.NavBar_menu-link.active .NavBar_menu-link-content-image {
  display: none;
}

.NavBar_menu-link.active .NavBar_menu-link-content-image-active {
  display: block;
}

.NavBar_menu-link .NavBar_menu-link-content-image {
  display: block;
}

.NavBar_menu-link .NavBar_menu-link-content-image-active {
  display: none;
}

.NavBar_menu-link {
  height: 50px;
  background: transparent;
  -webkit-border-radius: 160px / 50px;
  -moz-border-radius: 160px / 50px;
  border-radius: 160px / 50px;
  position: relative;
  top: 25px;
  margin: 0 20px;
  text-decoration: none;
  color: white;
}

.NavBar_menu-link-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 35px;
}

.NavBar_menu-link-content img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.NavBar_logo img {
  height: 32px;
  width: 32px;
}

.NavBar_menu-link-question {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: white;
}

.NavBar_menu-link-question img {
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  .NavBar,
  .NavBar_menu-link-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 700px) {
  .NavBar_menu-link-text,
  .NavBar_logo {
    display: none;
  }

  .NavBar_menu-link {
    margin: 0;
  }

  .NavBar_menu,
  .NavBar_menu-link,
  .NavBar_menu-link-content {
    width: 100%;
  }

  .NavBar_menu-link-content {
    margin: 0;
  }
}
