.SurpriseEmotion {
  display: flex;
  border-radius: 10px;
}

.SurpriseEmotion_left {
  background-color: #478c69;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.SurpriseEmotion_left-image {
  margin: 40px;
  width: 80%;
}

.SurpriseEmotion_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.SurpriseEmotion_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.SurpriseEmotion_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.SurpriseEmotion_right-title {
  color: #478c69;
  bottom: 20px;
  border-bottom: 1px solid #478c69;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.SurpriseEmotion_right-temperature {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #478c69;
  flex: 1 1;
}

.SurpriseEmotion_right-temperature-image {
  margin: 0 20px;
}

.SurpriseEmotion_right-temperature-right {
  margin: 0 20px 20px 0;
}

.SurpriseEmotion_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.SurpriseEmotion_right-surprise {
  display: flex;
  border-bottom: 1px solid #478c69;
  padding: 20px;
}

.SurpriseEmotion_right-surprise-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-surprise-content-text {
  text-align: left;
}

.SurpriseEmotion_right-question {
  padding: 20px;
  border-bottom: 1px solid #478c69;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SurpriseEmotion_right-question-title {
  font-weight: bold;
}

.SurpriseEmotion_right-question-content {
  margin-top: 10px;
}

.SurpriseEmotion_right-question-content-choice {
  display: flex;
  align-items: center;
}

.SurpriseEmotion_right-question-content-choice-text {
  margin-left: 20px;
}

.SurpriseEmotion_right-text {
  padding: 20px;
  border-bottom: 1px solid #478c69;
}

.SurpriseEmotion_right-text-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-text-text {
  text-align: left;
}

.SurpriseEmotion_right-introspection {
  display: flex;
  padding: 20px;
}

.SurpriseEmotion_right-introspection-content {
  margin-right: 20px;
}

.SurpriseEmotion_right-introspection-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-introspection-content-text {
  text-align: left;
}

@media screen and (max-width: 550px) {
  .SurpriseEmotion {
    flex-direction: column;
  }
  .SurpriseEmotion_left,
  .SurpriseEmotion_right {
    border-radius: 0;
  }
}
