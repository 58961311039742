.Trust {
  display: flex;
  border-radius: 10px;
}

.Trust_left {
  background-color: #a1c040;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Trust_left-image {
  margin: 40px;
  width: 80%;
}

.Trust_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Trust_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Trust_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Trust_right-title {
  color: #a1c040;
  bottom: 20px;
  border-bottom: 1px solid #a1c040;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Trust_right-temperature {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #a1c040;
  flex: 1 1;
}

.Trust_right-temperature-image {
  margin: 0 20px;
}

.Trust_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Trust_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Trust_right-clap-question {
  display: flex;
  border-bottom: 1px solid #a1c040;
}

.Trust_right-clap {
  border-right: 1px solid #a1c040;
  padding: 20px;
}

.Trust-right-question {
  padding: 20px;
}

.Trust_right-clap-text {
  margin-top: 10px;
}
.Trust_right-question-text {
  margin-bottom: 10px;
}

.Trust_right-softskill,
.Trust-right-trust {
  display: flex;
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #a1c040;
  align-items: center;
  justify-content: space-between;
}

.Trust_right-softskill-description-title,
.Trust-right-trust-description-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.Trust-right-trust-description {
  margin-left: 20px;
}

.Trust-right-trust-description-lorem {
  font-style: italic;
}

.Trust-right-signal {
  padding: 20px;
}

.Trust-right-signal-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Trust-right-signal-content {
  display: flex;
  text-align: left;
  margin-top: 20px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .Trust {
    flex-direction: column;
  }
  .Trust-left,
  .Trust-right {
    border-radius: 0;
  }
}
