.Challenge {
  text-align: left;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  min-width: 180px;
  max-height: 120px;
  max-width: 180px;
  margin: 0 20px 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
}

.Challenge:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.Challenge_heart {
  margin-right: 5px;
}

.Challenge_lock {
  position: absolute;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 5px;
}

.Challenge_lock img {
  margin: 20px 0;
}
