.Tinder .swipe {
  position: absolute;
}

.Tinder_header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.Tinder .cardContainer {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.Tinder .card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tinder .cardContent {
  width: 100%;
  height: 100%;
}

.Tinder .infoText {
  width: 100%;
  height: 28px;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.Tinder .buttons {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tinder .buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #da2d64;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 160px;
}

.Tinder .buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
