.ForgetPassword {
  display: flex;
  height: 100%;
}

.ForgetPassword_content {
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex: 1 1;
  background-color: white;
  color: #364257;
  justify-content: center;
}

.ForgetPassword_content-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ForgetPassword_content-title {
  margin-bottom: 30px;
}

.ForgetPassword_content-description {
  margin-bottom: 10px;
}

.ForgetPassword_form {
  margin: 5% 20%;
}
