.User {
  text-align: left;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  min-height: 120px;
  min-width: 180px;
  max-height: 120px;
  margin: 0 0 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
}

.User_details {
  flex: 1 1;
}

.User_content {
  display: flex;
  align-items: flex-start;
}

.User_picture {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.User_content-name {
  margin: 0 10px;
}

.User_button:hover {
  border: 1px solid white;
  border-radius: 20px;
  padding: 10px;
}

.User_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 10px;
  margin: 0 10px 0 0;
}

.User_email {
  display: flex;
  align-items: center;
}

.User_description {
  display: flex;
}

.User_description {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.User_score {
  color: #ffc107;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
}

.User_score img {
  height: 20px;
  margin-left: 10px;
}
