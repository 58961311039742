.QuestionsContent-modalIsOpen {
  z-index: 9998;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #d9d4e0;
  opacity: 0.9;
}

.QuestionsContent_header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.QuestionsContent_content-panel {
  display: flex;
  margin: 20px 5%;
}

.QuestionsContent_content-panel-navigation {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 5px;
  background: linear-gradient(25deg, #55408f 25%, rgba(0, 0, 0, 50%) 25%);
  max-height: 600px;
}

.QuestionsContent_content-panel-navigation-content {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}
.QuestionsContent_content_panel-right {
  flex: 3 1;
}

.QuestionsContent_content-panel-navigation-content--step {
  border: 1.5px solid white;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  opacity: 0.6;
}

.QuestionsContent_content-panel-navigation-content--step-current {
  border: 1.5px solid transparent;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background-color: rgba(218, 45, 100, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.QuestionsContent_content-panel-navigation-content--step-valid {
  border: 1.5px solid transparent;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background-color: rgba(25, 163, 169, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.QuestionsContent_content-panel-navigation-content--title {
  opacity: 0.6;
  text-transform: uppercase;
}

@media screen and (max-width: 750px) {
  .QuestionsContent_content-panel {
    flex-direction: column;
    margin: 0;
  }
  .QuestionsContent_content-panel-navigation {
    border-radius: 0;
    background: #501737;
  }

  .QuestionsContent_content-panel-navigation-image {
    display: none;
  }

  .QuestionsContent_content-panel-navigation-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .QuestionsContent_content-panel-navigation-content {
    flex-direction: column;
  }

  .QuestionsContent_content-panel-navigation-content--title,
  .QuestionsContent_content-panel-navigation-content--title-current {
    font-size: 10px;
  }

  .QuestionsContent_content-panel-navigation {
    padding: 10px;
  }
  .QuestionsContent_content-panel-navigation-content {
    margin-bottom: 0;
  }

  .QuestionsContent_content_panel-right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 430px) {
  .QuestionsContent_content-panel-navigation-content--title-current {
    font-size: 10px;
  }
}
