.QuestionIntroGame {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 40px 0 40px;
}

.QuestionIntroGame_text {
  margin-bottom: 40px;
}

.QuestionIntroGame_emotion {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.QuestionIntroGame_emotion-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.QuestionIntroGame_emotion-icon img {
  height: 48px;
  width: 48px;
  background-color: indigo;
  padding: 5px;
  border-radius: 5px;
}

.QuestionIntroGame_emotion-icon-name {
  margin: 10px 0;
}

.QuestionIntroGame_emotion-content {
  display: flex;
}

.QuestionIntroGame_emotion-content-right,
.QuestionIntroGame_emotion-content-left {
  flex: 1 1;
  height: 100%;
}

.QuestionIntroGame_emotion-content-left {
  margin-right: 5%;
}

.QuestionIntroGame_emotion-content-right-title,
.QuestionIntroGame_emotion-content-left-title {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.QuestionIntroGame_intro {
  margin: 5% 0;
}

.QuestionIntroGame_intro-title {
  margin-top: 5%;
}

.QuestionIntroGame-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
