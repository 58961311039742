.Profil_header_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Profil_header_content_buttons {
  margin-top: 20px;
}

.Profil_content {
  background-color: #3f2881;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 10% 15%;
  border-radius: 20px;
  padding-bottom: 20px;
}

.Profil_content-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 400px) {
  .Profil_content {
    background-color: transparent;
    width: 100%;
    margin: 25% 0;
  }
}
