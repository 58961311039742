.QuestionChoice {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 40px 40px 0 40px;
}

.QuestionChoice_challenge-answers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 5% 0;
}

.QuestionChoice_title-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
