.Memory {
  padding: 35px;
  width: 100%;
  margin: 20px auto;
  border-radius: 10px;
}

.Memory_header {
  display: flex;
  justify-content: space-around;
}

.playground {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  perspective: 1000px;
  margin: 50px 0;
}
.Card {
  width: 15%;
  user-select: none;
  height: 110px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 15px;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.Card div {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.6s;
  background: #da2d64;
}
.Card .Card_front {
  font-size: 50px;
  line-height: 120px;
  cursor: pointer;
  color: darken(#da2d64, 20%);
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: -webkit-linear-gradient(to top, #FFFFFF, #ECE9E6);
  background: linear-gradient(to top, #FFFFFF, #ECE9E6); */
}
.Card .Card_back {
  transform: rotateY(180deg);
  line-height: 110px;
}

.Card .Card_back img,
.Card_front_image {
  vertical-align: middle;
  height: 35px;
  width: 35px;
}
.Card.opened {
  transform: rotateY(180deg);
}
.Card.matched {
  transform: rotateY(180deg);
}
.Card.matched.Card_back {
  box-shadow: 0 0 0 2px rgba(#000, 0.05) inset;
  animation: selected 0.8s 0s ease 1;
  animation-fill-mode: both;
  opacity: 0.2;
}

@keyframes selected {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}

@media screen and (max-width: 600px) {
  .Card {
    width: 110px;
  }
}
