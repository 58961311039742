.storybook-box {
  border: 1px solid #d9d4e0;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  color: white;
}

.storybook-box-question-text {
  font-style: italic;
  margin: 0 5% 5% 0;
}

.storybook-box-question-quotes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .storybook-box-question {
    margin: 0;
  }
}
