.Spider {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spider Bottom To Top */

.SpiderBotomToTop {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderBotomToTop 10s 100000;
  animation-direction: normal;
}
.SpiderBotomToTopTwo {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderBotomToTopTwo 10s 100000;
  animation-direction: normal;
  left: 200px;
}
.SpiderBotomToTopThree {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderBotomToTopThree 10s 100000;
  animation-direction: normal;
  left: 400px;
}

.SpiderBotomToTopFour {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderBotomToTopFour 10s 100000;
  animation-direction: normal;
  left: 500px;
}

/* Spider Top to Bottom */

.SpiderTopToBottom {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderTopToBottom 10s 100000;
  animation-direction: normal;
  transform: rotate(180deg);
}

.SpiderLeftToRight {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderLeftToRight 10s 100000;
  animation-direction: normal;
  transform: rotate(90deg);
  animation-delay: 2s;
  left: -500px;
}

.SpiderRightToLeft {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spiderRightToLeft 10s 100000;
  animation-direction: normal;
  transform: rotate(265deg);
  animation-delay: 2s;
  left: 1300px;
  top: -150px;
}

@keyframes spiderBotomToTop {
  0% {
    top: 800px;
  }
  100% {
    top: -500px;
  }
}
@keyframes spiderBotomToTopTwo {
  0% {
    top: 800px;
    left: 200px;
  }
  100% {
    top: -500px;
    left: 200px;
  }
}

@keyframes spiderBotomToTopThree {
  0% {
    top: 800px;
    left: 400px;
  }
  100% {
    top: -500px;
    left: 400px;
  }
}
@keyframes spiderBotomToTopFour {
  0% {
    top: 800px;
    left: 500px;
  }
  100% {
    top: -500px;
    left: 500px;
  }
}

@keyframes spiderTopToBottom {
  0% {
    top: -500px;
  }
  100% {
    top: 800px;
  }
}

@keyframes spiderLeftToRight {
  0% {
    top: 0px;
    left: -500px;
  }
  100% {
    top: 0px;
    left: 1800px;
  }
}

@keyframes spiderLeftToRight {
  0% {
    top: 0px;
    left: -500px;
  }
  100% {
    top: 0px;
    left: 1800px;
  }
}

@keyframes spiderRightToLeft {
  0% {
    left: 1300px;
    top: -150px;
  }
  100% {
    left: -500px;
    top: -150px;
  }
}
