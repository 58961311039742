.storybook-modal {
  color: black;
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.storybook-modal-text {
  margin: 20px;
}

.storybook-modal-description {
  color: #717d92;
}

.storybook-modal-image {
  width: 100%;
}

@media screen and (max-width: 750px) {
  .Memory .storybook-modal {
    width: 100%;
  }
}

@media screen and (min-width: 1150px) {
  .Memory .storybook-modal-image {
    width: 60%;
  }
}
