.ViewUser_content {
  display: flex;
  flex-direction: column;
  margin: 0 10%;
}

.ViewUser_challenges {
  display: flex;
  flex-wrap: wrap;
}

.ViewUser_challenge {
  text-align: left;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-width: 20%;
  margin: 0 20px 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
  align-items: center;
}

.ViewUser_challenge:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.ViewUser_challenge-heart {
  margin-right: 5px;
}

.ViewUser_title {
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: left;
}

.ViewUser_bar {
  border-top: 1px solid white;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}

.ViewUser_emotion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.ViewUser_emotion-image {
  height: 30px;
}
