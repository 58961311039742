.storybook-button {
  font-weight: 700;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  outline: transparent;
  outline: none;
  animation: buttonAnimation 1s ease-in;
  margin: 10px;
}

@keyframes buttonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.storybook-button--primary {
  color: white;
  background-color: #da2d64;
  border: 1px solid transparent;
}

.storybook-button--primary:hover {
  color: #da2d64;
  background-color: white;
  border: 1px solid #da2d64;
}

.storybook-button--secondary {
  color: black;
  background-color: #e6e7e9;
}

.storybook-button--secondary:hover {
  color: grey;
  background-color: white;
}

.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 15px 35px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 15px 40px;
}

.storybook-button-image img {
  height: 50px;
  width: 50px;
  border-radius: 15px;
  border: 3px solid transparent;
}

.storybook-button-image {
  background-color: transparent;
  cursor: pointer;
  outline: transparent;
  outline: none;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  outline: transparent;
  outline: none;
}

.storybook-button-image:hover,
.storybook-button-image.isChecked {
  background-color: transparent;
  border-radius: 15px;
  border: 3px solid #da2d64;
}

.storybook-button-image {
  background-color: transparent;
  border-radius: 15px;
  border: 3px solid white;
  margin: 10px;
}
