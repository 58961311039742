.Angry {
  display: flex;
  border-radius: 10px;
}

.Angry_left {
  background-color: #ef60a5;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Angry_left-image {
  margin: 40px;
  width: 80%;
}

.Angry_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Angry_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Angry_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Angry_right-title {
  color: #ef60a5;
  bottom: 20px;
  border-bottom: 1px solid #ef60a5;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Angry_right-temperature {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #ef60a5;
  flex: 1 1;
}

.Angry_right-temperature-image {
  margin: 0 20px;
}

.Angry_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Angry_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Angry_right-air {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
}

.Angry_right-air-title,
.Angry_right-water-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Angry_right-water,
.Angry_right-trash,
.Angry_right-volcan {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
  display: flex;
}

.Angry_right-water-content {
  margin-left: 20px;
}

.Angry_right-anger {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
}

.Angry_right-anger-text {
  margin-bottom: 10px;
}

.Angry_right-trash-image {
  margin-left: 20px;
}

.Angry_right-volcan-content {
  margin-right: 20px;
}

.Angry_right-volcan-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}
