.Bot2 .react-chatbot-kit-chat-container {
  width: 800px;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  width: 100%;
}

.Bot2 .react-chatbot-kit-chat-input::-webkit-input-placeholder {
  color: transparent;
}

.Bot2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}

.Bot2 .react-chatbot-kit-chat-inner-container {
  background-color: rgba(255, 255, 255, 15%);
}

.Bot2 .react-chatbot-kit-chat-message-container {
  height: 464px;
  overflow-x: auto;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  background-color: rgba(0, 0, 0, 50%) 25%;
}

.Bot2 .react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid white !important;
  left: -9px !important;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  background-color: transparent !important;
  border: 1px solid white !important;
}

.Bot2 .react-chatbot-kit-user-avatar-container {
  background-color: #da2d64 !important;
}
