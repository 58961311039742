.PlutchickWheel {
  font-size: 12px;
  padding: 50px;
}

.PlutchickWheel_petals {
  cursor: pointer;
}

.PlutchickWheel_petals:hover {
  border: 1px solid black;
}

.PlutchickWheel_petals-opacity {
  opacity: 0.2;
}

.PlutchickWheel_question {
  text-align: left;
}

.PlutchickWheel_question_emotion {
  display: flex;
  align-items: center;
  margin: 20px 0 50px 0;
}

.PlutchickWheel_secondary {
  background-color: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: rgba(141, 160, 165, 1);
}
.PlutchickWheel {
  display: flex;
}

@media screen and (max-width: 650px) {
  .PlutchickWheel {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 750px) {
  .PlutchickWheel {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 950px) {
  .PlutchickWheel {
    display: flex;
    flex-direction: row;
  }
  .PlutchickWheel_question_emotion {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 1350px) {
  #plutchik-wheel {
    width: 50%;
  }
}
