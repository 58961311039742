.Signin {
  display: flex;
  height: 100%;
}

.Signin_content {
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex: 1 1;
  background-color: white;
  color: #364257;
  justify-content: center;
  height: 100vh;
}

.Signin_content-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signin_content-title {
  margin-bottom: 30px;
  font-weight: bold;
}

.Signin_content-description {
  margin-bottom: 10px;
}

.Signin_form {
  margin: 5% 20%;
}

.Signin_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
