.Challenges {
  margin: 5% 10%;
}

.Challenges_introduction {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Challenges_introduction-left {
  flex: 3 1;
  text-align: left;
  padding: 20px 20px 20px 0;
}

.Challenges_introduction-left h3 {
  margin-bottom: 10px;
}

.Challenges_introduction-right {
  flex: 1 1;
  padding: 20px 30px;
  background-color: #6a2e78;
  border-radius: 20px;
}

.Challenges_introduction-right-user {
  display: flex;
}

.Challenges_introduction-right-user-picture {
  height: 100%;
  width: 50%;
  border-radius: 50%;
  margin-right: 20px;
}

.Challenges_introduction-right-user-details {
  text-align: left;
}

.Challenges_introduction-right-user-details p {
  margin: 0 0 10px 0;
}

.Challenges_introduction-right-score {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.Challenges_introduction-right-score-points {
  margin-bottom: 10px;
}

.Challenges_introduction-right-score-points,
.Challenges_introduction-right-score-petals {
  display: flex;
  justify-content: space-between;
}

.Challenges_introduction-right-score-points-number,
.Challenges_introduction-right-score-petals-number {
  color: #ffc107;
  display: flex;
  align-items: center;
}

.Challenges_introduction-right-score-points-number img,
.Challenges_introduction-right-score-petals-number img {
  height: 23px;
  width: 23px;
  margin-left: 20px;
}

@media screen and (max-width: 700px) {
  .Challenges_introduction-right {
    display: none;
  }
}
