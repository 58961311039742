.ListPetals {
  background-color: #3f2881;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 10% 15%;
  border-radius: 20px;
  padding-bottom: 20px;
}

.ListPetals_profil {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  margin-top: -60px;
}

.ListPetals_bar {
  height: 1px;
  background-color: #ffffff;
  opacity: 0.5;
  margin: 20px 40px;
}

.ListPetals_content-tools {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListPetals_content-tool {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListPetals_content-tool img {
  margin: 0 10px;
}

.ListPetals #plutchik-wheel {
  font-size: 10px;
  margin: 20px;
}

.ListPetals .PlutchickWheel_petals-path {
  opacity: 0.5;
}
.ListPetals .PlutchickWheel_petals-blur {
  filter: blur(1px);
}

@media screen and (max-width: 700px) {
  .ListPetals {
    width: 100%;
    margin: 25% 0;
    background-color: transparent;
  }
}
