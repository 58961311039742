.storybook-alert {
  padding: 10px;
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.storybook-alert svg {
  height: 25px;
  width: 25px;
  margin-right: 3px;
}

.storybook-alert--primary {
  color: #c02514;
}

.storybook-alert--secondary {
  color: #00916e;
}
