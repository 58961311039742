.QuestionFeeling {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 40px 40px 0 40px;
}

.QuestionFeeling_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.QuestionFeeling_title {
  margin: 5% 0;
}

.QuestionFeeling_emotions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 5%;
  width: 100%;
}

.QuestionFeeling_emotions-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .QuestionFeeling_emotions {
    width: 80%;
  }
}
