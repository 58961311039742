.Intro {
  flex: 0.5 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

.Intro-bar {
  background-color: #fd4c39;
  height: 10px;
  width: 100px;
  margin-top: 5%;
}

.Intro-description {
  margin: 20% 0;
}

.Intro-logo {
  height: 150px;
  width: 150px;
}
