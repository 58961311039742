.QuestionSituations {
  color: black;
  color: black;
  display: flex;
  flex-wrap: wrap;
}

.QuestionSituations button {
  flex: 1 1;
}
