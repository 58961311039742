.Sadness {
  display: flex;
  border-radius: 10px;
}

.Sadness_left {
  background-color: #368da6;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Sadness_left-image {
  margin: 40px;
  width: 80%;
}

.Sadness_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Sadness_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Sadness_right {
  background-color: white;
  color: black;
  flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Sadness_right-title {
  color: #368da6;
  bottom: 20px;
  border-bottom: 1px solid #368da6;
  padding: 20px;
  flex: 1 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.Sadness_right-temperature {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #368da6;
  flex: 1 1;
}

.Sadness_right-temperature-image {
  margin: 0 20px;
}

.Sadness_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Sadness_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Sadness_right-eyes {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #368da6;
  flex: 1 1;
}

.Sadness_right-eyes-left {
  margin: 20px;
}

.Sadness_right-eyes-left-image {
  margin-right: 20px;
}

.Sadness_right-music {
  border-bottom: 1px solid #368da6;
  margin-top: 20px;
  flex: 1 1;
}

.Sadness_right-music-text {
  margin: 5px 20px 20px 20px;
}

.Sadness_right-write {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #368da6;
  flex: 1 1;
}

.Sadness_right-write {
  padding: 20px;
  justify-content: space-between;
}

.Sadness_right-write p,
.Sadness_right-environment p,
.Sadness_right-family p {
  text-align: left;
}

.Sadness_right-environment {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #368da6;
  flex: 1 1;
}

.Sadness_right-family {
  display: flex;
  align-items: center;
  padding: 20px;
  flex: 1 1;
}

.Sadness_right-family-image {
  margin-right: 20px;
}

@media screen and (max-width: 550px) {
  .Sadness {
    flex-direction: column;
  }
  .Sadness_left,
  .Sadness_right {
    border-radius: 0;
  }
}
