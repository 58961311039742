.storybook-link {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
.storybook-link--primary {
  color: white;
  border-bottom: 2px solid #f0b1c4;
}

.storybook-link--primary:hover {
  color: #da2d64;
  border-bottom: 2px solid white;
}

.storybook-link--secondary {
  color: #fd4c39;
  border-bottom: 2px solid #fd4c39;
}

.storybook-link--secondary:hover {
  color: #fd4c398a;
  border-bottom: 2px solid #fd4c398a;
}
