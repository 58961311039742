.UpdateProfil_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UpdateProfil_form_input {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.UpdateProfil_form_input_file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.UpdateProfil_form_input_file + label {
  color: white;
  background-color: #da2d64;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.UpdateProfil_form_input_file:focus + label,
.UpdateProfil_form_input_file + label:hover {
  background-color: white;
  color: #da2d64;
}

.UpdateProfil_form_input_file + label {
  cursor: pointer; /* "hand" cursor */
}

.UpdateProfil_form_input_file:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.UpdateProfil_form_input_picture {
  height: 25%;
  width: 25%;
  border-radius: 10px;
}

.Update_form_inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Update_form_inputs .storybook-input-content {
  margin-right: 20px;
  width: 40%;
}

@media screen and (max-width: 700px) {
  .UpdateProfil_form_input_picture {
    height: 50%;
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .Update_form_inputs {
    flex-direction: column;
    align-items: center;
  }

  .Update_form_inputs .storybook-input-content {
    width: 100%;
  }
}
