.anim-circle {
  color: transparent;
  position: absolute;
  transform: translate(0%, 0%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(188, 237, 243, 0.889);
  animation: breathe 12s linear infinite;
}

.Breathing {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Breathing button {
  margin-top: 70%;
}

.Breathing_parameter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
}

.Breathing_parameter_label {
  margin-top: 4%;
}

.Breathing_parameter_content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

@keyframes breathe {
  0% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(188, 237, 243, 0.3),
      0 0 0 50px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3),
      0 0 0 100px rgba(188, 237, 243, 0.3), 0 0 0 125px rgba(188, 237, 243, 0.3);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
}

@media screen and (max-width: 825px) {
  .Breathing button {
    margin-top: 100%;
  }
}

@media screen and (min-width: 1150px) {
  .Breathing button {
    margin-top: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .Breathing button {
    margin-top: 30%;
  }
}
