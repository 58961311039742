.Admin_content {
  margin: 10%;
}

.Admin_introduction-right {
  padding: 20px 30px;
  background-color: #6a2e78;
  border-radius: 20px;
  display: flex;
}
.Admin_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

.Admin_users {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Admin_users-content {
  display: flex;
  align-items: center;
}

.Admin_redirection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Admin_redirection-content {
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex: 1 1;
  background-color: white;
  color: #364257;
  justify-content: center;
  height: 100vh;
}

.Admin_top-challenges {
  display: flex;
}

.Admin_users-number {
  margin-right: 20px;
}
