.App {
  margin: 0;
  background-image: url('./styles/images/fond-opco.svg');
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(#da2d64, #53287d, #3f2881);
  color: white;
  background-size: 100%;
  background-attachment: fixed;
  margin: 0 auto;
}

@font-face {
  font-family: 'Gothic';
  src: url('/styles/font/gothic.ttf');
}
