.crazy-bot-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;

  background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/quokka%2Frobot.svg?alt=media&token=659bf945-32ff-4ac5-af54-d079e2ac6674');
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 5px;
}
