@import url(https://use.typekit.net/ykf1xtx.css);
.App {
  margin: 0;
  background-image: url(/static/media/fond-opco.baf31f6d.svg);
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(#da2d64, #53287d, #3f2881);
  color: white;
  background-size: 100%;
  background-attachment: fixed;
  margin: 0 auto;
}

@font-face {
  font-family: 'Gothic';
  src: url('/styles/font/gothic.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.not-button {
  background-color: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Intro {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 10%;
}

.Intro-bar {
  background-color: #fd4c39;
  height: 10px;
  width: 100px;
  margin-top: 5%;
}

.Intro-description {
  margin: 20% 0;
}

.Intro-logo {
  height: 150px;
  width: 150px;
}

.storybook-input-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.storybook-input-content-input {
  display: -webkit-flex;
  display: flex;
  margin: 10px 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.storybook-label {
  margin: 10px 0;
  text-align: left;
  color: white;
}

.storybook-input {
  border: 1.6px solid #9aa2b0;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  outline: none;
}

.storybook-input--primary {
  color: #8da0a5;
  background-color: white;
}

.storybook-input--secondary {
  color: black;
  background-color: transparent;
}

.storybook-input-radio {
  border: 1px solid white;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  background-color: transparent;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 15%);
}

.storybook-input-radio:hover,
.storybook-input-radio.isChecked {
  border: 1px solid #da2d64;
  padding: 10px;
  border-radius: 3px;
  width: 100%;
  min-width: 200px;
  outline: transparent;
  cursor: pointer;
  background-color: transparent;
  color: white;
  background-color: rgba(255, 255, 255, 15%);
}

/* .storybook-input-radio-circle {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 10px;
  margin-left: 20px;
}

.storybook-input-radio:hover .storybook-input-radio-circle {
  background-color: #da2d64;
} */

.not-button {
  position: absolute;
  margin-right: 15px;
}

.not-button svg {
  fill: #8da0a5;
}

.Signin_form-inputs {
  margin-bottom: 25px;
}

.storybook-button {
  font-weight: 700;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  outline: transparent;
  outline: none;
  -webkit-animation: buttonAnimation 1s ease-in;
          animation: buttonAnimation 1s ease-in;
  margin: 10px;
}

@-webkit-keyframes buttonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes buttonAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.storybook-button--primary {
  color: white;
  background-color: #da2d64;
  border: 1px solid transparent;
}

.storybook-button--primary:hover {
  color: #da2d64;
  background-color: white;
  border: 1px solid #da2d64;
}

.storybook-button--secondary {
  color: black;
  background-color: #e6e7e9;
}

.storybook-button--secondary:hover {
  color: grey;
  background-color: white;
}

.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 15px 35px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 15px 40px;
}

.storybook-button-image img {
  height: 50px;
  width: 50px;
  border-radius: 15px;
  border: 3px solid transparent;
}

.storybook-button-image {
  background-color: transparent;
  cursor: pointer;
  outline: transparent;
  outline: none;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  outline: transparent;
  outline: none;
}

.storybook-button-image:hover,
.storybook-button-image.isChecked {
  background-color: transparent;
  border-radius: 15px;
  border: 3px solid #da2d64;
}

.storybook-button-image {
  background-color: transparent;
  border-radius: 15px;
  border: 3px solid white;
  margin: 10px;
}

.storybook-link {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
.storybook-link--primary {
  color: white;
  border-bottom: 2px solid #f0b1c4;
}

.storybook-link--primary:hover {
  color: #da2d64;
  border-bottom: 2px solid white;
}

.storybook-link--secondary {
  color: #fd4c39;
  border-bottom: 2px solid #fd4c39;
}

.storybook-link--secondary:hover {
  color: #fd4c398a;
  border-bottom: 2px solid #fd4c398a;
}

.storybook-alert {
  padding: 10px;
  margin: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.storybook-alert svg {
  height: 25px;
  width: 25px;
  margin-right: 3px;
}

.storybook-alert--primary {
  color: #c02514;
}

.storybook-alert--secondary {
  color: #00916e;
}

.Signin {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.Signin_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  color: #364257;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
}

.Signin_content-top {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.Signin_content-title {
  margin-bottom: 30px;
  font-weight: bold;
}

.Signin_content-description {
  margin-bottom: 10px;
}

.Signin_form {
  margin: 5% 20%;
}

.Signin_links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Signup {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.Signup_form {
  margin: 5% 20%;
}

.storybook-box {
  border: 1px solid #d9d4e0;
  border-radius: 3px;
  padding: 10px;
  background-color: transparent;
  color: white;
}

.storybook-box-question-text {
  font-style: italic;
  margin: 0 5% 5% 0;
}

.storybook-box-question-quotes {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .storybook-box-question {
    margin: 0;
  }
}

.FirstConnexion_intro {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 15%;
}

.FirstConnexion_firstname {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 15%;
  width: 50% !important;
  margin-left: 25%;
}

.FirstConnexion_intro_image {
  margin: 30px;
}

.NavBar {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #331443;
  padding: 0 10px 10px 10px;
  font-size: 14px;
}

.NavBar_logo {
  background-color: transparent;
  border: none;
  text-decoration: none;
}

.NavBar_menu {
  display: -webkit-flex;
  display: flex;
}

.NavBar_menu-link.active {
  background: #331443;
  color: #ffc107;
}

.NavBar_menu-link.active .NavBar_menu-link-content-image {
  display: none;
}

.NavBar_menu-link.active .NavBar_menu-link-content-image-active {
  display: block;
}

.NavBar_menu-link .NavBar_menu-link-content-image {
  display: block;
}

.NavBar_menu-link .NavBar_menu-link-content-image-active {
  display: none;
}

.NavBar_menu-link {
  height: 50px;
  background: transparent;
  border-radius: 160px / 50px;
  position: relative;
  top: 25px;
  margin: 0 20px;
  text-decoration: none;
  color: white;
}

.NavBar_menu-link-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 35px;
}

.NavBar_menu-link-content img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.NavBar_logo img {
  height: 32px;
  width: 32px;
}

.NavBar_menu-link-question {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: white;
}

.NavBar_menu-link-question img {
  margin-right: 10px;
}

@media screen and (max-width: 900px) {
  .NavBar,
  .NavBar_menu-link-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 700px) {
  .NavBar_menu-link-text,
  .NavBar_logo {
    display: none;
  }

  .NavBar_menu-link {
    margin: 0;
  }

  .NavBar_menu,
  .NavBar_menu-link,
  .NavBar_menu-link-content {
    width: 100%;
  }

  .NavBar_menu-link-content {
    margin: 0;
  }
}

.Challenge {
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 120px;
  min-width: 180px;
  max-height: 120px;
  max-width: 180px;
  margin: 0 20px 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
}

.Challenge:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.Challenge_heart {
  margin-right: 5px;
}

.Challenge_lock {
  position: absolute;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 0 0 0 5px;
}

.Challenge_lock img {
  margin: 20px 0;
}

.TypeOfChallenges {
  margin: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.TypeOfChallenges_name {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.TypeOfChallenges_challenges {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Challenges {
  margin: 5% 10%;
}

.Challenges_introduction {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Challenges_introduction-left {
  -webkit-flex: 3 1;
          flex: 3 1;
  text-align: left;
  padding: 20px 20px 20px 0;
}

.Challenges_introduction-left h3 {
  margin-bottom: 10px;
}

.Challenges_introduction-right {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 20px 30px;
  background-color: #6a2e78;
  border-radius: 20px;
}

.Challenges_introduction-right-user {
  display: -webkit-flex;
  display: flex;
}

.Challenges_introduction-right-user-picture {
  height: 100%;
  width: 50%;
  border-radius: 50%;
  margin-right: 20px;
}

.Challenges_introduction-right-user-details {
  text-align: left;
}

.Challenges_introduction-right-user-details p {
  margin: 0 0 10px 0;
}

.Challenges_introduction-right-score {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px;
}

.Challenges_introduction-right-score-points {
  margin-bottom: 10px;
}

.Challenges_introduction-right-score-points,
.Challenges_introduction-right-score-petals {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Challenges_introduction-right-score-points-number,
.Challenges_introduction-right-score-petals-number {
  color: #ffc107;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Challenges_introduction-right-score-points-number img,
.Challenges_introduction-right-score-petals-number img {
  height: 23px;
  width: 23px;
  margin-left: 20px;
}

@media screen and (max-width: 700px) {
  .Challenges_introduction-right {
    display: none;
  }
}


.QuestionFeeling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  padding: 40px 40px 0 40px;
}

.QuestionFeeling_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
}

.QuestionFeeling_title {
  margin: 5% 0;
}

.QuestionFeeling_emotions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-align: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 5%;
  width: 100%;
}

.QuestionFeeling_emotions-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media screen and (max-width: 500px) {
  .QuestionFeeling_emotions {
    width: 80%;
  }
}

.QuestionChoice {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  padding: 40px 40px 0 40px;
}

.QuestionChoice_challenge-answers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 100%;
  margin: 5% 0;
}

.QuestionChoice_title-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.QuestionSolution {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  padding: 0 40px 0 40px;
}

.QuestionSolution_information {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px 40px 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.QuestionSolution_information img {
  height: 35px;
  width: 35px;
  margin-bottom: 20px;
}

.QuestionSolution_information-link-text {
  margin: 20px 0;
}

.QuestionSolution li {
  margin-left: 40px;
}

.QuestionSolution_practice {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px;
  border-radius: 10px;
  margin: 5% 0;
}

.QuestionSolution_practice-title {
  text-transform: uppercase;
  margin-bottom: 20px;
}

.QuestionSolution_practice-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.QuestionIntroGame {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  padding: 0 40px 0 40px;
}

.QuestionIntroGame_text {
  margin-bottom: 40px;
}

.QuestionIntroGame_emotion {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 15%);
  padding: 20px 40px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.QuestionIntroGame_emotion-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 20px;
}

.QuestionIntroGame_emotion-icon img {
  height: 48px;
  width: 48px;
  background-color: indigo;
  padding: 5px;
  border-radius: 5px;
}

.QuestionIntroGame_emotion-icon-name {
  margin: 10px 0;
}

.QuestionIntroGame_emotion-content {
  display: -webkit-flex;
  display: flex;
}

.QuestionIntroGame_emotion-content-right,
.QuestionIntroGame_emotion-content-left {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100%;
}

.QuestionIntroGame_emotion-content-left {
  margin-right: 5%;
}

.QuestionIntroGame_emotion-content-right-title,
.QuestionIntroGame_emotion-content-left-title {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.QuestionIntroGame_intro {
  margin: 5% 0;
}

.QuestionIntroGame_intro-title {
  margin-top: 5%;
}

.QuestionIntroGame-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.storybook-modal {
  color: black;
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px;
  border-radius: 10px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  display: -webkit-flex;
  display: flex;
  padding: 50px;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.storybook-modal-text {
  margin: 20px;
}

.storybook-modal-description {
  color: #717d92;
}

.storybook-modal-image {
  width: 100%;
}

@media screen and (max-width: 750px) {
  .Memory .storybook-modal {
    width: 100%;
  }
}

@media screen and (min-width: 1150px) {
  .Memory .storybook-modal-image {
    width: 60%;
  }
}

.anim-circle {
  color: transparent;
  position: absolute;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(188, 237, 243, 0.889);
  -webkit-animation: breathe 12s linear infinite;
          animation: breathe 12s linear infinite;
}

.Breathing {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Breathing button {
  margin-top: 70%;
}

.Breathing_parameter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Breathing_parameter_label {
  margin-top: 4%;
}

.Breathing_parameter_content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 0;
}

@-webkit-keyframes breathe {
  0% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(188, 237, 243, 0.3),
      0 0 0 50px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3),
      0 0 0 100px rgba(188, 237, 243, 0.3), 0 0 0 125px rgba(188, 237, 243, 0.3);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
}

@keyframes breathe {
  0% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
  50% {
    box-shadow: 0 0 0 25px rgba(188, 237, 243, 0.3),
      0 0 0 50px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3),
      0 0 0 100px rgba(188, 237, 243, 0.3), 0 0 0 125px rgba(188, 237, 243, 0.3);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(188, 237, 243, 0.3),
      0 0 0 30px rgba(188, 237, 243, 0.3), 0 0 0 45px rgba(188, 237, 243, 0.3),
      0 0 0 60px rgba(188, 237, 243, 0.3), 0 0 0 75px rgba(188, 237, 243, 0.3);
  }
}

@media screen and (max-width: 825px) {
  .Breathing button {
    margin-top: 100%;
  }
}

@media screen and (min-width: 1150px) {
  .Breathing button {
    margin-top: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .Breathing button {
    margin-top: 30%;
  }
}

.Comfort_quokka {
  margin: 20px 0;
}

.List_form {
  margin: 20px 20%;
}

.List_input_add {
  display: -webkit-flex;
  display: flex;
}

.Spider {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/* Spider Bottom To Top */

.SpiderBotomToTop {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderBotomToTop 10s 100000;
          animation: spiderBotomToTop 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
}
.SpiderBotomToTopTwo {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderBotomToTopTwo 10s 100000;
          animation: spiderBotomToTopTwo 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  left: 200px;
}
.SpiderBotomToTopThree {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderBotomToTopThree 10s 100000;
          animation: spiderBotomToTopThree 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  left: 400px;
}

.SpiderBotomToTopFour {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderBotomToTopFour 10s 100000;
          animation: spiderBotomToTopFour 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  left: 500px;
}

/* Spider Top to Bottom */

.SpiderTopToBottom {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderTopToBottom 10s 100000;
          animation: spiderTopToBottom 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SpiderLeftToRight {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderLeftToRight 10s 100000;
          animation: spiderLeftToRight 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  left: -500px;
}

.SpiderRightToLeft {
  width: 100px;
  height: 100px;
  position: relative;
  -webkit-animation: spiderRightToLeft 10s 100000;
          animation: spiderRightToLeft 10s 100000;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-transform: rotate(265deg);
          transform: rotate(265deg);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  left: 1300px;
  top: -150px;
}

@-webkit-keyframes spiderBotomToTop {
  0% {
    top: 800px;
  }
  100% {
    top: -500px;
  }
}

@keyframes spiderBotomToTop {
  0% {
    top: 800px;
  }
  100% {
    top: -500px;
  }
}
@-webkit-keyframes spiderBotomToTopTwo {
  0% {
    top: 800px;
    left: 200px;
  }
  100% {
    top: -500px;
    left: 200px;
  }
}
@keyframes spiderBotomToTopTwo {
  0% {
    top: 800px;
    left: 200px;
  }
  100% {
    top: -500px;
    left: 200px;
  }
}

@-webkit-keyframes spiderBotomToTopThree {
  0% {
    top: 800px;
    left: 400px;
  }
  100% {
    top: -500px;
    left: 400px;
  }
}

@keyframes spiderBotomToTopThree {
  0% {
    top: 800px;
    left: 400px;
  }
  100% {
    top: -500px;
    left: 400px;
  }
}
@-webkit-keyframes spiderBotomToTopFour {
  0% {
    top: 800px;
    left: 500px;
  }
  100% {
    top: -500px;
    left: 500px;
  }
}
@keyframes spiderBotomToTopFour {
  0% {
    top: 800px;
    left: 500px;
  }
  100% {
    top: -500px;
    left: 500px;
  }
}

@-webkit-keyframes spiderTopToBottom {
  0% {
    top: -500px;
  }
  100% {
    top: 800px;
  }
}

@keyframes spiderTopToBottom {
  0% {
    top: -500px;
  }
  100% {
    top: 800px;
  }
}

@-webkit-keyframes spiderLeftToRight {
  0% {
    top: 0px;
    left: -500px;
  }
  100% {
    top: 0px;
    left: 1800px;
  }
}

@keyframes spiderLeftToRight {
  0% {
    top: 0px;
    left: -500px;
  }
  100% {
    top: 0px;
    left: 1800px;
  }
}

@keyframes spiderLeftToRight {
  0% {
    top: 0px;
    left: -500px;
  }
  100% {
    top: 0px;
    left: 1800px;
  }
}

@-webkit-keyframes spiderRightToLeft {
  0% {
    left: 1300px;
    top: -150px;
  }
  100% {
    left: -500px;
    top: -150px;
  }
}

@keyframes spiderRightToLeft {
  0% {
    left: 1300px;
    top: -150px;
  }
  100% {
    left: -500px;
    top: -150px;
  }
}

/* Box */

.Surprise {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.box {
  --boxInnerText: '';
  position: relative;
  width: 1rem;
  height: 1rem;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateX(-25deg) rotateY(-25deg);
          transform: rotateX(-25deg) rotateY(-25deg);
}

.box-side {
  box-sizing: border-box;
  position: absolute;
  width: 12rem;
  height: 12rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  border: 0.01rem solid rgba(60, 40, 60, 0.65);
  background: #da2d64
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='18' viewBox='0 0 100 18'%3E%3Cpath fill='%233d263d' fill-opacity='0.4' d='M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z'%3E%3C/path%3E%3C/svg%3E");
}

.box-side span,
.box-side-top::after {
  -webkit-text-stroke: 0.03em #eee;
}

.box-side-front {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: initial;
          align-items: initial;
  font-size: 0.25rem;
  -webkit-transform: translateZ(11.5rem);
          transform: translateZ(11.5rem);
}

.box-side-front span {
  font-size: 30px;
}

.box-side-front::before,
.box-side-front::after {
  position: absolute;
  font-size: 0.2rem;
  min-width: 1.5em;
}

/* .box-side-front::before {
  content: '🕷️';
  transform: translateX(0.02rem) rotate(-20deg);
}

.box-side-front::after {
  content: '🍭';
  transform: translateZ(-2.35rem) translateX(3.65rem) rotate(90deg);
} */

.box-side-back {
  -webkit-transform: rotateY(180deg) translateZ(0.5rem);
          transform: rotateY(180deg) translateZ(0.5rem);
}

.box-side-left {
  -webkit-transform: rotateY(-90deg) translateX(5.5rem) translateZ(6rem);
          transform: rotateY(-90deg) translateX(5.5rem) translateZ(6rem);
}

.box-side-right {
  font-size: 0.2rem;
  -webkit-transform: rotateY(90deg) translateX(-5.5rem) translateZ(6rem);
          transform: rotateY(90deg) translateX(-5.5rem) translateZ(6rem);
}

/* .box-side-right::after {
  content: '🎃';
  font-size: 0.4rem;
} */

.box-side-top {
  -webkit-transform-origin: 0 0 0;
          transform-origin: 0 0 0;
  -webkit-transform: translateZ(-0.5rem) rotateX(90deg);
          transform: translateZ(-0.5rem) rotateX(90deg);
}

.box-side-top::before,
.box-side-top::after {
  position: absolute;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

/* .box-side-top::before {
  content: '☠️';
  font-size: 0.4rem;
} */

.box-side-top::after {
  content: var(--boxInnerText);
  font-size: 0.35rem;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateY(180deg) rotateZ(180deg) translateZ(0.00001rem);
          transform: rotateY(180deg) rotateZ(180deg) translateZ(0.00001rem);
}

.box-side-bottom {
  -webkit-transform: rotateX(-90deg) translateY(-5.5rem) translateZ(6rem);
          transform: rotateX(-90deg) translateY(-5.5rem) translateZ(6rem);
}

.emoji {
  position: absolute;
  font-size: 0.5rem;
  -webkit-transform: translate(calc(4.5rem - 50%), 4.5rem);
          transform: translate(calc(4.5rem - 50%), 4.5rem);
  font-size: 52px;
}

.or-text {
  font-size: 0.2rem;
  margin: 0.2em 0;
}

/* Open Box States */

.box-open .box-side {
  cursor: auto;
}

.box-open .box-side-top {
  -webkit-animation: openBox 1.2s ease-out forwards;
          animation: openBox 1.2s ease-out forwards;
}

.box-open .emoji {
  -webkit-animation: raiseEmoji 1s 1s ease-out forwards,
    rotateEmoji 3s 2s linear infinite;
          animation: raiseEmoji 1s 1s ease-out forwards,
    rotateEmoji 3s 2s linear infinite;
}

.Surprise_button {
  height: 500px;
}

/* Animations */

@-webkit-keyframes openBox {
  100% {
    -webkit-transform: translateZ(-0.5rem) rotateX(252deg);
            transform: translateZ(-0.5rem) rotateX(252deg);
  }
}

@keyframes openBox {
  100% {
    -webkit-transform: translateZ(-0.5rem) rotateX(252deg);
            transform: translateZ(-0.5rem) rotateX(252deg);
  }
}

@-webkit-keyframes raiseEmoji {
  100% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem);
            transform: translate(calc(0.5rem - 50%), -0.5rem);
    margin-top: -87px;
    margin-left: 62px;
    font-size: 52px;
  }
}

@keyframes raiseEmoji {
  100% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem);
            transform: translate(calc(0.5rem - 50%), -0.5rem);
    margin-top: -87px;
    margin-left: 62px;
    font-size: 52px;
  }
}

@-webkit-keyframes rotateEmoji {
  0% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(0deg);
            transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(0deg);
  }
  100% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(360deg);
            transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(360deg);
  }
}

@keyframes rotateEmoji {
  0% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(0deg);
            transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(0deg);
  }
  100% {
    -webkit-transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(360deg);
            transform: translate(calc(0.5rem - 50%), -0.5rem) rotateY(360deg);
  }
}

.crazy-bot-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;

  background-image: url('https://firebasestorage.googleapis.com/v0/b/digital-room-289213.appspot.com/o/quokka%2Frobot.svg?alt=media&token=659bf945-32ff-4ac5-af54-d079e2ac6674');
  margin-right: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 5px;
}

.todos-widget {
  color: black;
}

.todos-widget-list {
  margin: 0;
  padding: 0;
}

.todos-widget-list-item {
  font-size: 1rem;
  margin: 8px 0;
  text-align: left;
}

.flashcard-container {
  padding: 15px;
  border-radius: 3px;
  background-color: #f1e6e6;
}

.flashcard-button {
  background: transparent;
  padding: 8px;
  border-radius: 3px;
  border-color: blue;
  margin: 5px;
  width: 150px;
}

.QuestionSituations {
  color: black;
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.QuestionSituations button {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.StepAnger {
  color: white;
  text-align: left;
  border: 1px solid white;
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
}

.StepGuilty {
  border: 1px solid white;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.StepGuilty_input {
  margin: 0 5px;
  border-radius: 2px;
  border: none;
  height: 100%;
  padding: 10px;
}

.ExplainLose {
  border: 1px solid white;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  flex-wrap: wrap;
}

.ExplainLose_input {
  margin: 0 5px;
  border-radius: 2px;
  border: none;
  height: 100%;
  padding: 10px;
}

.QuestionLesson {
  border: 1px solid white;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.QuestionLesson_input {
  margin: 0 10px;

  margin: 0 5px;
  border-radius: 2px;
  border: none;
  height: 100%;
  padding: 10px;
}

.Bot2 .react-chatbot-kit-chat-container {
  width: 800px;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  width: 100%;
}

.Bot2 .react-chatbot-kit-chat-input::-webkit-input-placeholder {
  color: transparent;
}

.Bot2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Bot2 .react-chatbot-kit-chat-inner-container {
  background-color: rgba(255, 255, 255, 15%);
}

.Bot2 .react-chatbot-kit-chat-message-container {
  height: 464px;
  overflow-x: auto;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  background-color: rgba(0, 0, 0, 50%) 25%;
}

.Bot2 .react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid white !important;
  left: -9px !important;
}

.Bot2 .react-chatbot-kit-chat-bot-message {
  background-color: transparent !important;
  border: 1px solid white !important;
}

.Bot2 .react-chatbot-kit-user-avatar-container {
  background-color: #da2d64 !important;
}

.Memory {
  padding: 35px;
  width: 100%;
  margin: 20px auto;
  border-radius: 10px;
}

.Memory_header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.playground {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  margin: 50px 0;
}
.Card {
  width: 15%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 110px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 15px;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
}
.Card div {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.6s;
  background: #da2d64;
}
.Card .Card_front {
  font-size: 50px;
  line-height: 120px;
  cursor: pointer;
  color: darken(#da2d64, 20%);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* background: -webkit-linear-gradient(to top, #FFFFFF, #ECE9E6);
  background: linear-gradient(to top, #FFFFFF, #ECE9E6); */
}
.Card .Card_back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  line-height: 110px;
}

.Card .Card_back img,
.Card_front_image {
  vertical-align: middle;
  height: 35px;
  width: 35px;
}
.Card.opened {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.Card.matched {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.Card.matched.Card_back {
  box-shadow: 0 0 0 2px rgba(#000, 0.05) inset;
  -webkit-animation: selected 0.8s 0s ease 1;
          animation: selected 0.8s 0s ease 1;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0.2;
}

@-webkit-keyframes selected {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes selected {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}

@media screen and (max-width: 600px) {
  .Card {
    width: 110px;
  }
}

.Recycle {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: 'sofia-pro', sans-serif;
  cursor: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1518293165/hcc/hand4.svg'),
    auto;
  counter-reset: game;
  color: black;
}

.game {
  width: 100%;
  height: 100%;
  background-color: #6ecddd;
  z-index: 1;
}

.goodObject:checked {
  counter-increment: game 2;
}

.badObject:checked {
  counter-increment: game -1;
}

.score::after {
  content: counter(game);
  height: 40%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

#badObjects input[type='checkbox'],
#goodObjects input[type='checkbox'] {
  width: 120px;
  top: -180px;
  height: 120px;
  position: absolute;
  background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  cursor: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1518293165/hcc/grab4.svg'),
    auto;
  -webkit-appearance: none;
  appearance: none;
  padding: 20px;
  margin: 20px;
}

.goodObject:nth-child(1) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good3.svg');
  -webkit-animation-duration: 5.1722122411s;
          animation-duration: 5.1722122411s;
  -webkit-animation-delay: 11.7162577591s;
          animation-delay: 11.7162577591s;
  left: 83%;
}
.goodObject:nth-child(2) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good2.svg');
  -webkit-animation-duration: 4.5625691319s;
          animation-duration: 4.5625691319s;
  -webkit-animation-delay: 11.0544471525s;
          animation-delay: 11.0544471525s;
  left: 88%;
}
.goodObject:nth-child(3) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good2.svg');
  -webkit-animation-duration: 3.412041594s;
          animation-duration: 3.412041594s;
  -webkit-animation-delay: 12.3012284939s;
          animation-delay: 12.3012284939s;
  left: 65%;
}
.goodObject:nth-child(4) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good3.svg');
  -webkit-animation-duration: 5.299791591s;
          animation-duration: 5.299791591s;
  -webkit-animation-delay: 9.6815641404s;
          animation-delay: 9.6815641404s;
  left: 69%;
}
.goodObject:nth-child(5) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good1.svg');
  -webkit-animation-duration: 4.2736302246s;
          animation-duration: 4.2736302246s;
  -webkit-animation-delay: 11.981297313s;
          animation-delay: 11.981297313s;
  left: 59%;
}
.goodObject:nth-child(6) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good3.svg');
  -webkit-animation-duration: 4.0549043323s;
          animation-duration: 4.0549043323s;
  -webkit-animation-delay: 12.0256553909s;
          animation-delay: 12.0256553909s;
  left: 12%;
}
.goodObject:nth-child(7) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good2.svg');
  -webkit-animation-duration: 3.7365824658s;
          animation-duration: 3.7365824658s;
  -webkit-animation-delay: 10.7361450935s;
          animation-delay: 10.7361450935s;
  left: 24%;
}
.goodObject:nth-child(8) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good1.svg');
  -webkit-animation-duration: 3.0461843669s;
          animation-duration: 3.0461843669s;
  -webkit-animation-delay: 12.1154269165s;
          animation-delay: 12.1154269165s;
  left: 70%;
}
.goodObject:nth-child(9) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good1.svg');
  -webkit-animation-duration: 4.418138833s;
          animation-duration: 4.418138833s;
  -webkit-animation-delay: 10.4234039632s;
          animation-delay: 10.4234039632s;
  left: 13%;
}
.goodObject:nth-child(10) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/good3.svg');
  -webkit-animation-duration: 5.7123312452s;
          animation-duration: 5.7123312452s;
  -webkit-animation-delay: 12.0155045886s;
          animation-delay: 12.0155045886s;
  left: 41%;
}

.badObject:nth-child(1) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad1.svg');
  -webkit-animation-duration: 4.9756479185s;
          animation-duration: 4.9756479185s;
  -webkit-animation-delay: 9.2925978251s;
          animation-delay: 9.2925978251s;
  left: 12%;
}
.badObject:nth-child(2) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad5.svg');
  -webkit-animation-duration: 4.9831109112s;
          animation-duration: 4.9831109112s;
  -webkit-animation-delay: 11.0389560969s;
          animation-delay: 11.0389560969s;
  left: 23%;
}
.badObject:nth-child(3) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad1.svg');
  -webkit-animation-duration: 4.3046370246s;
          animation-duration: 4.3046370246s;
  -webkit-animation-delay: 12.2550743237s;
          animation-delay: 12.2550743237s;
  left: 74%;
}
.badObject:nth-child(4) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad1.svg');
  -webkit-animation-duration: 3.7192034709s;
          animation-duration: 3.7192034709s;
  -webkit-animation-delay: 11.5686680676s;
          animation-delay: 11.5686680676s;
  left: 86%;
}
.badObject:nth-child(5) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad4.svg');
  -webkit-animation-duration: 4.7272960296s;
          animation-duration: 4.7272960296s;
  -webkit-animation-delay: 12.4245867135s;
          animation-delay: 12.4245867135s;
  left: 61%;
}
.badObject:nth-child(6) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad3.svg');
  -webkit-animation-duration: 4.6384782812s;
          animation-duration: 4.6384782812s;
  -webkit-animation-delay: 11.3435727586s;
          animation-delay: 11.3435727586s;
  left: 16%;
}
.badObject:nth-child(7) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad1.svg');
  -webkit-animation-duration: 3.2255787416s;
          animation-duration: 3.2255787416s;
  -webkit-animation-delay: 12.6732318532s;
          animation-delay: 12.6732318532s;
  left: 44%;
}
.badObject:nth-child(8) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad5.svg');
  -webkit-animation-duration: 5.1625990227s;
          animation-duration: 5.1625990227s;
  -webkit-animation-delay: 11.6467835822s;
          animation-delay: 11.6467835822s;
  left: 19%;
}
.badObject:nth-child(9) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad5.svg');
  -webkit-animation-duration: 5.6133826496s;
          animation-duration: 5.6133826496s;
  -webkit-animation-delay: 10.2228574364s;
          animation-delay: 10.2228574364s;
  left: 76%;
}
.badObject:nth-child(10) {
  background: url('https://res.cloudinary.com/dilgjzsjl/image/upload/v1517495694/hcc/icons/bad1.svg');
  -webkit-animation-duration: 5.0347662433s;
          animation-duration: 5.0347662433s;
  -webkit-animation-delay: 11.6902770433s;
          animation-delay: 11.6902770433s;
  left: 49%;
}

.score {
  position: relative;
  margin: 0;
  color: #f0f8ff24;
  font-size: 50vh;
  position: absolute;
  top: 10vh;
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  z-index: 2;
}

input[type='checkbox'] {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

input[type='checkbox']:focus {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}

input[type='checkbox']:checked {
  pointer-events: none;
  -webkit-animation: hitFade 2s;
  animation: hitFade 2s;
}

@-webkit-keyframes hitFade {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hitFade {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#badObjects input[type='checkbox']:checked:after,
#goodObjects input[type='checkbox']:checked:after {
  position: fixed;
  left: 0;
  top: 20vh;
  width: 100vw;
  height: 100vh;
  text-align: center;
  font-size: 8.5em;
  font-weight: 600;
  -webkit-animation: fade 1s;
          animation: fade 1s;
  z-index: -1;
  opacity: 0;
}

#badObjects input[type='checkbox']:checked:after {
  content: '-1!';
  color: red;
}

#goodObjects input[type='checkbox']:checked:after {
  content: '+2!';
  color: white;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.goodObject,
.badObject {
  -webkit-animation-name: falling;
          animation-name: falling;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  z-index: 3;
}

@-webkit-keyframes falling {
  from {
    -webkit-transform: translateY(-120px);
            transform: translateY(-120px);
  }
  to {
    -webkit-transform: translateY(150vh);
            transform: translateY(150vh);
  }
}

@keyframes falling {
  from {
    -webkit-transform: translateY(-120px);
            transform: translateY(-120px);
  }
  to {
    -webkit-transform: translateY(150vh);
            transform: translateY(150vh);
  }
}
.rules {
  opacity: 1;
  display: block;
  width: auto;
  height: auto;
  text-align: center;
  padding: 0px 50px 30px;
  background-color: #fff;
  box-shadow: 2px 2px 1px 1pxrgba 56, 58, 68, 0.466;
  border-radius: 6px;
  z-index: 9999;
  overflow-y: scroll;
  max-height: 90vh;
}

.rules h1 {
  text-align: center;
  font-weight: 900;
  font-family: 'sofia-pro', sans-serif;
}

.rulesCont {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.items {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.items img {
  width: 40px;
  height: 40px;
}

.item {
  width: 300px;
  height: auto;
  text-align: center;
}

.avoid,
.collect {
  position: relative;
  width: 40%;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.startScreen,
.endScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #6ecddd;
}

.startScreen {
  opacity: 0;
  z-index: -9999;
  -webkit-animation: fadeOut 9s linear;
          animation: fadeOut 9s linear;
}

.endScreen {
  opacity: 1;
  z-index: 9999;
  -webkit-animation: fadeIn 25s linear;
          animation: fadeIn 25s linear;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    z-index: 9999;
  }
  90% {
    opacity: 1;
    z-index: 9999;
  }
  98% {
    opacity: 0;
    z-index: 9999;
  }
  100% {
    z-index: -9999;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    z-index: 9999;
  }
  90% {
    opacity: 1;
    z-index: 9999;
  }
  98% {
    opacity: 0;
    z-index: 9999;
  }
  100% {
    z-index: -9999;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: -9999;
  }
  97% {
    opacity: 0;
    z-index: -9999;
  }
  98% {
    opacity: 0;
    z-index: 9999;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    z-index: -9999;
  }
  97% {
    opacity: 0;
    z-index: -9999;
  }
  98% {
    opacity: 0;
    z-index: 9999;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.countdownBar {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 5;
  background-color: #9edbe8;
  height: 50px;
}

.countdownBar:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: #388da0;
  left: 0;
  -webkit-animation: countDown 20s 10s linear;
          animation: countDown 20s 10s linear;
}

@-webkit-keyframes countDown {
  0% {
    width: 0%;
  }
  85% {
    width: 85%;
    background-color: #388da0;
  }
  85.1% {
    width: 85.1%;
    background-color: #c56971;
  }
  100% {
    width: 100%;
    background-color: #c56971;
  }
}

@keyframes countDown {
  0% {
    width: 0%;
  }
  85% {
    width: 85%;
    background-color: #388da0;
  }
  85.1% {
    width: 85.1%;
    background-color: #c56971;
  }
  100% {
    width: 100%;
    background-color: #c56971;
  }
}

.Tinder .swipe {
  position: absolute;
}

.Tinder_header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 20px 0;
}

.Tinder .cardContainer {
  height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: black;
}

.Tinder .card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Tinder .cardContent {
  width: 100%;
  height: 100%;
}

.Tinder .infoText {
  width: 100%;
  height: 28px;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  color: #fff;
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
}

.Tinder .buttons {
  margin: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Tinder .buttons button {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #da2d64;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 160px;
}

.Tinder .buttons button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@-webkit-keyframes popup {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  30% {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  57% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  64% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes popup {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  10% {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
  }
  30% {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  57% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  64% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.PlutchickWheel {
  font-size: 12px;
  padding: 50px;
}

.PlutchickWheel_petals {
  cursor: pointer;
}

.PlutchickWheel_petals:hover {
  border: 1px solid black;
}

.PlutchickWheel_petals-opacity {
  opacity: 0.2;
}

.PlutchickWheel_question {
  text-align: left;
}

.PlutchickWheel_question_emotion {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px 0 50px 0;
}

.PlutchickWheel_secondary {
  background-color: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px;
  color: rgba(141, 160, 165, 1);
}
.PlutchickWheel {
  display: -webkit-flex;
  display: flex;
}

@media screen and (max-width: 650px) {
  .PlutchickWheel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (min-width: 750px) {
  .PlutchickWheel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (min-width: 950px) {
  .PlutchickWheel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .PlutchickWheel_question_emotion {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (min-width: 1350px) {
  #plutchik-wheel {
    width: 50%;
  }
}

.Joy {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Joy_left {
  background-color: #fcbd01;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Joy_left-image {
  margin: 40px;
  width: 80%;
}

.Joy_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Joy_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Joy_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Joy_right-title {
  color: #fcbd01;
  bottom: 20px;
  border-bottom: 1px solid #fcbd01;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Joy_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #fcbd01;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Joy_right-temperature-image {
  margin: 0 20px;
}

.Joy_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Joy_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Joy_right-music-question {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #fcbd01;
}

.Joy_right-music {
  border-right: 1px solid #fcbd01;
  padding: 20px;
}

.Joy-right-question {
  padding: 20px;
}

.Joy_right-music-text {
  margin-top: 10px;
}
.Joy_right-question-text {
  margin-bottom: 10px;
}

.Joy-right-lorem {
  border-bottom: 1px solid #fcbd01;
  padding: 20px;
}

.Joy-right-lorem-latin {
  font-style: italic;
  margin-bottom: 10px;
}

.Joy-right-lorem-latin-description {
  text-align: left;
}

.Joy-right-trophy {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
}

.Joy-right-trophy-content {
  margin-left: 20px;
  text-align: left;
}

.Joy-right-trophy-content-lorem {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-style: italic;
}

.Joy-right-trophy-content-lorem-text {
  text-align: left;
}
.Joy-right-trophy-content-lorem-author {
  text-align: right;
}

.Joy-right-trophy-content-text {
  margin-top: 10px;
}

@media screen and (max-width: 550px) {
  .Joy {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Joy-left,
  .Joy-right {
    border-radius: 0;
  }
}

.Sadness {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Sadness_left {
  background-color: #368da6;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Sadness_left-image {
  margin: 40px;
  width: 80%;
}

.Sadness_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Sadness_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Sadness_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Sadness_right-title {
  color: #368da6;
  bottom: 20px;
  border-bottom: 1px solid #368da6;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Sadness_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #368da6;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-temperature-image {
  margin: 0 20px;
}

.Sadness_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Sadness_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Sadness_right-eyes {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 1px solid #368da6;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-eyes-left {
  margin: 20px;
}

.Sadness_right-eyes-left-image {
  margin-right: 20px;
}

.Sadness_right-music {
  border-bottom: 1px solid #368da6;
  margin-top: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-music-text {
  margin: 5px 20px 20px 20px;
}

.Sadness_right-write {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 1px solid #368da6;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-write {
  padding: 20px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Sadness_right-write p,
.Sadness_right-environment p,
.Sadness_right-family p {
  text-align: left;
}

.Sadness_right-environment {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #368da6;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-family {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Sadness_right-family-image {
  margin-right: 20px;
}

@media screen and (max-width: 550px) {
  .Sadness {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Sadness_left,
  .Sadness_right {
    border-radius: 0;
  }
}

.Trust {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Trust_left {
  background-color: #a1c040;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Trust_left-image {
  margin: 40px;
  width: 80%;
}

.Trust_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Trust_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Trust_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Trust_right-title {
  color: #a1c040;
  bottom: 20px;
  border-bottom: 1px solid #a1c040;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Trust_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #a1c040;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Trust_right-temperature-image {
  margin: 0 20px;
}

.Trust_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Trust_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Trust_right-clap-question {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #a1c040;
}

.Trust_right-clap {
  border-right: 1px solid #a1c040;
  padding: 20px;
}

.Trust-right-question {
  padding: 20px;
}

.Trust_right-clap-text {
  margin-top: 10px;
}
.Trust_right-question-text {
  margin-bottom: 10px;
}

.Trust_right-softskill,
.Trust-right-trust {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #a1c040;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Trust_right-softskill-description-title,
.Trust-right-trust-description-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.Trust-right-trust-description {
  margin-left: 20px;
}

.Trust-right-trust-description-lorem {
  font-style: italic;
}

.Trust-right-signal {
  padding: 20px;
}

.Trust-right-signal-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Trust-right-signal-content {
  display: -webkit-flex;
  display: flex;
  text-align: left;
  margin-top: 20px;
  font-size: 14px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@media screen and (max-width: 550px) {
  .Trust {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Trust-left,
  .Trust-right {
    border-radius: 0;
  }
}

.Fear {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Fear_left {
  background-color: #6fa83f;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Fear_left-image {
  margin: 40px;
  width: 80%;
}

.Fear_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Fear_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Fear_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Fear_right-title {
  color: #6fa83f;
  bottom: 20px;
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Fear_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #6fa83f;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Fear_right-temperature-image {
  margin: 0 20px;
}

.Fear_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Fear_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Fear_right-zen,
.Fear_right-friend {
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Fear_right-zen-text,
.Fear_right-friend-text {
  margin-right: 20px;
  text-align: left;
}

.Fear_right-zen-text {
  margin: 5px 20px 20px 20px;
}

.Fear_right-song {
  padding: 20px;
  border-bottom: 1px solid #6fa83f;
}

.Fear_right-environment p {
  text-align: left;
}

.Fear_right-environment {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #6fa83f;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Fear_right-freeze {
  border-bottom: 1px solid #6fa83f;
  padding: 20px;
}

.Fear_right-freeze-title {
  font-weight: bold;
}

.Fear_right-reactions {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
}

.Fear_right-reactions-left-reaction,
.Fear_right-reactions-right-reaction {
  display: -webkit-flex;
  display: flex;
}

.Fear_right-reactions-left,
.Fear_right-reactions-right,
.Fear_right-reactions-right-reaction {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 5px;
}

.Fear_right-reactions-left-reaction,
.Fear_right-reactions-right-reaction,
.Fear_right-reactions-left-reaction {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Fear_right-reactions-left-reaction-text {
  margin-left: 20px;
  text-align: left;
}

@media screen and (max-width: 550px) {
  .Fear {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Fear_left,
  .Fear_right {
    border-radius: 0;
  }
}

.SurpriseEmotion {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.SurpriseEmotion_left {
  background-color: #478c69;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.SurpriseEmotion_left-image {
  margin: 40px;
  width: 80%;
}

.SurpriseEmotion_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.SurpriseEmotion_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.SurpriseEmotion_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.SurpriseEmotion_right-title {
  color: #478c69;
  bottom: 20px;
  border-bottom: 1px solid #478c69;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.SurpriseEmotion_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #478c69;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.SurpriseEmotion_right-temperature-image {
  margin: 0 20px;
}

.SurpriseEmotion_right-temperature-right {
  margin: 0 20px 20px 0;
}

.SurpriseEmotion_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.SurpriseEmotion_right-surprise {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #478c69;
  padding: 20px;
}

.SurpriseEmotion_right-surprise-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-surprise-content-text {
  text-align: left;
}

.SurpriseEmotion_right-question {
  padding: 20px;
  border-bottom: 1px solid #478c69;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.SurpriseEmotion_right-question-title {
  font-weight: bold;
}

.SurpriseEmotion_right-question-content {
  margin-top: 10px;
}

.SurpriseEmotion_right-question-content-choice {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.SurpriseEmotion_right-question-content-choice-text {
  margin-left: 20px;
}

.SurpriseEmotion_right-text {
  padding: 20px;
  border-bottom: 1px solid #478c69;
}

.SurpriseEmotion_right-text-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-text-text {
  text-align: left;
}

.SurpriseEmotion_right-introspection {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
}

.SurpriseEmotion_right-introspection-content {
  margin-right: 20px;
}

.SurpriseEmotion_right-introspection-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.SurpriseEmotion_right-introspection-content-text {
  text-align: left;
}

@media screen and (max-width: 550px) {
  .SurpriseEmotion {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .SurpriseEmotion_left,
  .SurpriseEmotion_right {
    border-radius: 0;
  }
}

.Disgust {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Disgust_left {
  background-color: #8851a9;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Disgust_left-image {
  margin: 40px;
  width: 80%;
}

.Disgust_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Disgust_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Disgust_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Disgust_right-title {
  color: #8851a9;
  bottom: 20px;
  border-bottom: 1px solid #8851a9;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Disgust_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
  border-bottom: 1px solid #8851a9;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Disgust_right-temperature-image {
  margin: 0 20px;
}

.Disgust_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Disgust_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Disgust_right-question {
  padding: 20px;
  border-bottom: 1px solid #8851a9;
}

.Disgust_right-question-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Disgust_right-principes {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #8851a9;
}

.Disgust_right-principes-text {
  text-align: left;
  margin: 0 0 20px 20px;
}

.Disgust_right-expression,
.Disgust_right-spirit {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #8851a9;
}
.Disgust_right-expression-content {
  text-align: left;
  margin-right: 20px;
}

.Disgust_right-expression-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Disgust_right-spirit-content {
  margin-left: 20px;
}

.Disgust_right-spirit-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Disgust_right-spirit-content-text {
  text-align: left;
}

@media screen and (max-width: 550px) {
  .Disgust {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Disgust_left,
  .Disgust_right {
    border-radius: 0;
  }
}

.Angry {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Angry_left {
  background-color: #ef60a5;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Angry_left-image {
  margin: 40px;
  width: 80%;
}

.Angry_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Angry_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Angry_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Angry_right-title {
  color: #ef60a5;
  bottom: 20px;
  border-bottom: 1px solid #ef60a5;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Angry_right-temperature {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  border-bottom: 1px solid #ef60a5;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Angry_right-temperature-image {
  margin: 0 20px;
}

.Angry_right-temperature-right {
  margin: 0 20px 20px 0;
}

.Angry_right-temperature-numbers {
  font-weight: bold;
  margin-top: 5px;
}

.Angry_right-air {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
}

.Angry_right-air-title,
.Angry_right-water-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Angry_right-water,
.Angry_right-trash,
.Angry_right-volcan {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
  display: -webkit-flex;
  display: flex;
}

.Angry_right-water-content {
  margin-left: 20px;
}

.Angry_right-anger {
  padding: 20px;
  border-bottom: 1px solid #ef60a5;
}

.Angry_right-anger-text {
  margin-bottom: 10px;
}

.Angry_right-trash-image {
  margin-left: 20px;
}

.Angry_right-volcan-content {
  margin-right: 20px;
}

.Angry_right-volcan-content-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Anticipation {
  display: -webkit-flex;
  display: flex;
  border-radius: 10px;
}

.Anticipation_left {
  background-color: #ff7d35;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Anticipation_left-image {
  margin: 40px;
  width: 80%;
}

.Anticipation_left-tricky {
  height: 40px;
  width: 40px;
  margin: 20px;
}

.Anticipation_left-description p {
  text-align: left;
  margin: 40px 20px 0 20px;
}

.Anticipation_right {
  background-color: white;
  color: black;
  -webkit-flex: 1 1;
          flex: 1 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Anticipation_right-title {
  color: #ff7d35;
  bottom: 20px;
  border-bottom: 1px solid #ff7d35;
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 20px;
}

.Anticipation_right-question,
.Anticipation_right-fall,
.Anticipation_right-goals,
.Anticipation_right-calendar {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #ff7d35;
  padding: 20px;
}

.Anticipation_right-question-content {
  margin-left: 20px;
}

.Anticipation_right-question-content-title,
.Anticipation_right-fall-content-title,
.Anticipation_right-goals-content-title,
.Anticipation_right-calendar-content-title,
.Anticipation_right-fight-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.Anticipation_right-fall-content {
  margin-right: 20px;
}
.Anticipation_right-fall-content-lists {
  margin-top: 10px;
}

.Anticipation_right-fall-content-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Anticipation_right-fall-content-list-text {
  margin-left: 10px;
  text-align: left;
}

.Anticipation_right-goals {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Anticipation_right-goals-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.Anticipation_right-goals-lists {
  margin-left: 10px;
}

.Anticipation_right-goals-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Anticipation_right-goals-list-checkbox {
  height: 10px;
  width: 10px;
  border: 1px solid #ff7d35;
  border-radius: 2px;
  margin-right: 5px;
}

.Anticipation_right-calendar-content {
  text-align: left;
  margin-left: 20px;
}

.Anticipation_right-fight {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Anticipation_right-fight-content {
  text-align: left;
  margin-bottom: 10px;
}

@media screen and (max-width: 550px) {
  .Anticipation {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Anticipation_left,
  .Anticipation_right {
    border-radius: 0;
  }
}

.QuestionDebrief {
  margin: 0 20px;
}

.QuestionDebrief-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.QuestionDebrief #plutchik-wheel {
  margin-right: 30%;
}

.QuestionDebrief_plus {
  color: #ffc107;
}

@media screen and (max-width: 550px) {
  .QuestionDebrief {
    margin: 0;
  }
}

.QuestionsContent-modalIsOpen {
  z-index: 9998;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #d9d4e0;
  opacity: 0.9;
}

.QuestionsContent_header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-top: 20px;
}

.QuestionsContent_content-panel {
  display: -webkit-flex;
  display: flex;
  margin: 20px 5%;
}

.QuestionsContent_content-panel-navigation {
  padding: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-radius: 5px;
  background: linear-gradient(25deg, #55408f 25%, rgba(0, 0, 0, 50%) 25%);
  max-height: 600px;
}

.QuestionsContent_content-panel-navigation-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}
.QuestionsContent_content_panel-right {
  -webkit-flex: 3 1;
          flex: 3 1;
}

.QuestionsContent_content-panel-navigation-content--step {
  border: 1.5px solid white;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 10px;
  opacity: 0.6;
}

.QuestionsContent_content-panel-navigation-content--step-current {
  border: 1.5px solid transparent;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background-color: rgba(218, 45, 100, 1);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 10px;
}

.QuestionsContent_content-panel-navigation-content--step-valid {
  border: 1.5px solid transparent;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  background-color: rgba(25, 163, 169, 1);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 10px;
}

.QuestionsContent_content-panel-navigation-content--title {
  opacity: 0.6;
  text-transform: uppercase;
}

@media screen and (max-width: 750px) {
  .QuestionsContent_content-panel {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 0;
  }
  .QuestionsContent_content-panel-navigation {
    border-radius: 0;
    background: #501737;
  }

  .QuestionsContent_content-panel-navigation-image {
    display: none;
  }

  .QuestionsContent_content-panel-navigation-titles {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
  }

  .QuestionsContent_content-panel-navigation-content {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .QuestionsContent_content-panel-navigation-content--title,
  .QuestionsContent_content-panel-navigation-content--title-current {
    font-size: 10px;
  }

  .QuestionsContent_content-panel-navigation {
    padding: 10px;
  }
  .QuestionsContent_content-panel-navigation-content {
    margin-bottom: 0;
  }

  .QuestionsContent_content_panel-right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 430px) {
  .QuestionsContent_content-panel-navigation-content--title-current {
    font-size: 10px;
  }
}

.UpdateProfil_form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.UpdateProfil_form_input {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-align-items: center;
          align-items: center;
}

.UpdateProfil_form_input_file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.UpdateProfil_form_input_file + label {
  color: white;
  background-color: #da2d64;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}

.UpdateProfil_form_input_file:focus + label,
.UpdateProfil_form_input_file + label:hover {
  background-color: white;
  color: #da2d64;
}

.UpdateProfil_form_input_file + label {
  cursor: pointer; /* "hand" cursor */
}

.UpdateProfil_form_input_file:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.UpdateProfil_form_input_picture {
  height: 25%;
  width: 25%;
  border-radius: 10px;
}

.Update_form_inputs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.Update_form_inputs .storybook-input-content {
  margin-right: 20px;
  width: 40%;
}

@media screen and (max-width: 700px) {
  .UpdateProfil_form_input_picture {
    height: 50%;
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .Update_form_inputs {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  .Update_form_inputs .storybook-input-content {
    width: 100%;
  }
}

.Profil_header_content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Profil_header_content_buttons {
  margin-top: 20px;
}

.Profil_content {
  background-color: #3f2881;
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin: 10% 15%;
  border-radius: 20px;
  padding-bottom: 20px;
}

.Profil_content-top {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 400px) {
  .Profil_content {
    background-color: transparent;
    width: 100%;
    margin: 25% 0;
  }
}

.ForgetPassword {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.ForgetPassword_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  color: #364257;
  -webkit-justify-content: center;
          justify-content: center;
}

.ForgetPassword_content-top {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.ForgetPassword_content-title {
  margin-bottom: 30px;
}

.ForgetPassword_content-description {
  margin-bottom: 10px;
}

.ForgetPassword_form {
  margin: 5% 20%;
}

.ResetPassword {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.ResetPassword_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  color: black;
  -webkit-justify-content: center;
          justify-content: center;
}

.ResetPassword_content-top {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.ResetPassword_content-title {
  margin-bottom: 30px;
}

.ResetPassword_content-description {
  margin-bottom: 10px;
}

.ResetPassword_form {
  margin: 5% 20%;
}

.SignupSuccess {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.SignupSuccess_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  color: #364257;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 15%;
}

.SignupSuccess_content svg {
  background-color: #f8d5e0;
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50px;
}

.SignupSuccess_content-title {
  margin: 25px 0;
}

.SignupSuccess_content-description {
  color: #9aa2b0;
  margin-bottom: 50px;
}

.ListPetals {
  background-color: #3f2881;
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  margin: 10% 15%;
  border-radius: 20px;
  padding-bottom: 20px;
}

.ListPetals_profil {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  margin-top: -60px;
}

.ListPetals_bar {
  height: 1px;
  background-color: #ffffff;
  opacity: 0.5;
  margin: 20px 40px;
}

.ListPetals_content-tools {
  margin: 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ListPetals_content-tool {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.ListPetals_content-tool img {
  margin: 0 10px;
}

.ListPetals #plutchik-wheel {
  font-size: 10px;
  margin: 20px;
}

.ListPetals .PlutchickWheel_petals-path {
  opacity: 0.5;
}
.ListPetals .PlutchickWheel_petals-blur {
  -webkit-filter: blur(1px);
          filter: blur(1px);
}

@media screen and (max-width: 700px) {
  .ListPetals {
    width: 100%;
    margin: 25% 0;
    background-color: transparent;
  }
}


.Admin_content {
  margin: 10%;
}

.Admin_introduction-right {
  padding: 20px 30px;
  background-color: #6a2e78;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
}
.Admin_title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Admin_users {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Admin_users-content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Admin_redirection {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Admin_redirection-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-flex: 1 1;
          flex: 1 1;
  background-color: white;
  color: #364257;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
}

.Admin_top-challenges {
  display: -webkit-flex;
  display: flex;
}

.Admin_users-number {
  margin-right: 20px;
}

.User {
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  min-height: 120px;
  min-width: 180px;
  max-height: 120px;
  margin: 0 0 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
}

.User_details {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.User_content {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.User_picture {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.User_content-name {
  margin: 0 10px;
}

.User_button:hover {
  border: 1px solid white;
  border-radius: 20px;
  padding: 10px;
}

.User_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 10px;
  margin: 0 10px 0 0;
}

.User_email {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.User_description {
  display: -webkit-flex;
  display: flex;
}

.User_description {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}

.User_score {
  color: #ffc107;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 10px;
}

.User_score img {
  height: 20px;
  margin-left: 10px;
}

.pagination-container{display:-webkit-flex;display:flex;list-style-type:none;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.pagination-container .pagination-item{padding:0 12px;height:32px;text-align:center;margin:auto 4px;color:white;display:-webkit-flex;display:flex;box-sizing:border-box;-webkit-align-items:center;align-items:center;letter-spacing:0.01071em;border-radius:16px;line-height:1.43;font-size:13px;min-width:32px}.pagination-container .pagination-item.dots:hover{background-color:transparent;cursor:default}.pagination-container .pagination-item:hover{background-color:rgba(243,235,235,0.253);cursor:pointer}.pagination-container .pagination-item.selected{background-color:rgba(243,235,235,0.253)}.pagination-container .pagination-item .arrow::before{position:relative;content:'';display:inline-block;width:0.4em;height:0.4em;border-right:0.12em solid white;border-top:0.12em solid white}.pagination-container .pagination-item .arrow.left{-webkit-transform:rotate(-135deg) translate(-50%);transform:rotate(-135deg) translate(-50%)}.pagination-container .pagination-item .arrow.right{-webkit-transform:rotate(45deg);transform:rotate(45deg)}.pagination-container .pagination-item.disabled{pointer-events:none}.pagination-container .pagination-item.disabled .arrow::before{border-right:0.12em solid white;border-top:0.12em solid white}.pagination-container .pagination-item.disabled:hover{background-color:transparent;cursor:default}

.Users {
  color: white;
}

.Users_lists {
  padding: 10%;
}

.ViewUser_content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 10%;
}

.ViewUser_challenges {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.ViewUser_challenge {
  text-align: left;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 20%;
  margin: 0 20px 20px 0;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: relative;
  background-blend-mode: darken;
  padding: 15px;
  -webkit-align-items: center;
          align-items: center;
}

.ViewUser_challenge:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.ViewUser_challenge-heart {
  margin-right: 5px;
}

.ViewUser_title {
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: left;
}

.ViewUser_bar {
  border-top: 1px solid white;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}

.ViewUser_emotion {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 10px 0;
}

.ViewUser_emotion-image {
  height: 30px;
}

