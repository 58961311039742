.QuestionDebrief {
  margin: 0 20px;
}

.QuestionDebrief-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.QuestionDebrief #plutchik-wheel {
  margin-right: 30%;
}

.QuestionDebrief_plus {
  color: #ffc107;
}

@media screen and (max-width: 550px) {
  .QuestionDebrief {
    margin: 0;
  }
}
