.StepGuilty {
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StepGuilty_input {
  margin: 0 5px;
  border-radius: 2px;
  border: none;
  height: 100%;
  padding: 10px;
}
