.todos-widget {
  color: black;
}

.todos-widget-list {
  margin: 0;
  padding: 0;
}

.todos-widget-list-item {
  font-size: 1rem;
  margin: 8px 0;
  text-align: left;
}
